<template>
   <LoadingState :message="$translate('checkinMessage')" />
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted } from 'vue';
import { useVisitConditionsStore } from '../store/visit-conditions';
import { useRouter } from 'vue-router';
import { RouteName } from '../router/route-name';
import { useVisitStore } from '../store/visit';
import type { AxiosError } from 'axios';
import { useI18n } from 'vue-i18n';
import { denyCheckin } from '../dynamic-form/composables/use-deny-checkin';
import router from '../router';
import LoadingState from '@/components/LoadingState.vue';

const visitStore = useVisitStore();
const i18n = useI18n();

// If the user has not scanned a QR code yet (no visit settings)
// Or has not filled out the input fields yet (no input fields),
// redirect to the invalid QR code page
onBeforeMount(() => {
  const router = useRouter();
  const visitConditionsStore = useVisitConditionsStore();
  const visitStore = useVisitStore();
  if (!visitConditionsStore.visitSettings || !visitStore?.inputFields) {
    router.push({ name: RouteName.InvalidQrCode });
  }
});

onMounted(async () => {
  try {
    await visitStore.checkin();
    await router.replace({ name: RouteName.CheckedIn });
  } catch (e) {
    let errorMessage = ((e as AxiosError).response?.data as { error: { message: string, details: unknown }})?.error?.message;
    if (!errorMessage) {
      errorMessage = (e as Error).message;
    }
    denyCheckin({ [i18n.locale.value]: i18n.t(errorMessage) }, false);
  }
});
</script>